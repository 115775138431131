import React, {CSSProperties, useEffect, useRef, useState} from "react";
import Modal from 'react-modal';
import {Clinic} from "../../../models/clinic.model";
import {
  Button,
  ClinicLogo,
  ClinicName,
  Close, 
  CoachName,
  Description,
  Dot,
  Icon, 
  IconCT,
  LeftSide,
  MainInfo, 
  Place,
  Price,
  RightSide, 
  Space, 
  Spots,
  When,
  Wrapper
} from "./style";
import closeIcon from "../../../assets/images/close-icon.svg";
import peopleIcon from "../../../assets/images/people-icon.svg";
import clockIcon from "../../../assets/images/clock-icon-big.svg";
import placeIcon from "../../../assets/images/place-icon.svg";
import coachIcon from "../../../assets/images/coach-icon.svg";
import moment from "moment";
import GoogleMaps from "../../google-maps/google-maps";
import MoreDates from "../../more-dates/more-dates";
import AboutCoach from "./components/about-coach";
import {isDesktop, isMobile, isTablet} from "react-device-detect";

Modal.setAppElement('#root');

const modalStyles: CSSProperties = {
  width: '100%',
  maxHeight: isMobile ? '93%' : '90%',
  maxWidth: '1045px',
  top: '50%',
  left: '50%',
  right: 'auto',
  bottom: 'auto',
  marginRight: '-50%',
  transform: isMobile ? 'translate(-50%, -46%)' : 'translate(-50%, -50%)',
  borderRadius: '10px',
  boxSizing: 'border-box',
  padding: '0'
};

const styles = {
  content: modalStyles
}

interface ModalProps {
  isOpen: boolean,
  handleCloseModal: () => void,
  clinic: Clinic,
}

declare global {
  interface Window {
    gtag: (...args: any[]) => void;
  }
}

const AboutClinicModal: React.FC<ModalProps> = (props) => {
  const {isOpen, handleCloseModal, clinic} = props;
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [showCoachTab, setShowCoachTab] = useState<boolean>(false);
  const [showMore, setShowMore] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen && clinic) {
      setModalIsOpen(true);
    } else {
      closeModal();
    }
  }, [isOpen])

  const closeModal = () => {
    setModalIsOpen(false);
    setShowCoachTab(false);
    handleCloseModal();
  }

  const getStartDate = (): string => {
    return new Date(clinic.StartTime.seconds*1000).toString();
  }

  const getEndDate = (): string => {
    return new Date(clinic.EndTime.seconds*1000).toString();
  }

  const handleRegisterClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
  
    e.preventDefault(); // Prevent immediate navigation
    
    const url = clinic.CoachData?.CoachURL;
  
    // Call the conversion tracking
    if (window.gtag) {
      console.log('Calling gtag with event, conversion'); // Log before calling gtag
      window.gtag('event', 'conversion', {
        'send_to': 'AW-11227002398/XkK-CL_LwsYZEJ7suekp',
        'event_callback': function() {
          // Navigate to the URL after tracking
          if (url) {
            window.open(url, '_blank');
          }
        }
      });
    } else {
      // Fallback if gtag isn't available
      if (url) {
        window.open(url, '_blank');
      }
    }
  };

  return (
    clinic && <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Clinic Modal"
        shouldCloseOnOverlayClick={false}
        style={styles}
    >
        <Wrapper className={isMobile ? 'mobile-view':''}>
          <Close src={closeIcon} onClick={closeModal}/>
          {
            !showCoachTab &&
            <>
              <LeftSide className={isMobile ? 'mobile-view' : ''}>
                {isMobile && !isTablet && <ClinicName>{clinic.ClinicName?.id}</ClinicName>}
                <ClinicLogo src={clinic.ClinicImage} backgroundColor={clinic.colorBackground}/>
                {(isDesktop || isTablet) &&
                  <>
                    <Button 
                      href={clinic.CoachData?.CoachURL} 
                      onClick={handleRegisterClick}
                    >
                      {clinic.Spots > 0 ? 'Register' : 'Join the Waitlist'}
                    </Button>
                    <Price>{clinic.Cost}</Price>
                  </>
                }
              </LeftSide>
              <RightSide className={isMobile ? 'mobile-view' : ''}>
                {(isDesktop || isTablet) &&
                  <ClinicName>{clinic.ClinicName?.id} <Spots>{clinic.Spots > 0 ? `${clinic.Spots === 1 ? `${clinic.Spots + ' spot left'}` : `${clinic.Spots + ' spots left'}`}` : 'Waitlist'}</Spots></ClinicName>
                }
                <MainInfo>
                  <Icon src={peopleIcon}></Icon>
                  {clinic.AgeText}
                  <Dot/>
                  {clinic.SkillLevelText}
                  <Dot/>
                  {clinic.Duration}
                </MainInfo>
                <Description>
                  <input onChange={() => setShowMore(!showMore)} type="checkbox" id="expanded" />
                  <span className={isMobile ? 'mobile-view' : ''}>{clinic.ClinicName?.ClinicDescription}</span>
                  {isMobile && <label htmlFor="expanded" role="button"> {showMore ? 'Read less' : 'Read more'}</label>}
                </Description>
                <CoachName className={isMobile ? 'mobile-view' : ''}>
                  <IconCT>
                    <Icon src={coachIcon}/>
                    Coach:
                  </IconCT>
                  <span className={isMobile ? 'mobile-view' : ''}>
                    <span onClick={() => setShowCoachTab(true)}>{clinic.CoachData?.id}</span>
                  </span>
                </CoachName>
                <Place className={isMobile ? 'mobile-view' : ''}>
                  <IconCT>
                    <Icon src={placeIcon}/>
                    Where:
                  </IconCT>
                  <span className={isMobile ? 'mobile-view' : ''}> {clinic.LocationName} </span>
                </Place>
                <When className={isMobile ? 'mobile-view' : ''}>
                  <IconCT>
                    <Icon src={clockIcon} />
                    When:
                  </IconCT>
                  <span>
                    {moment(getStartDate()).format("dddd, MMM Do")}
                    <Dot/>
                    {moment(getStartDate()).format("h:mmA")}
                    <Space>-</Space>
                    {moment(getEndDate()).format("h:mmA")}
                  </span>
                  {!!clinic.listDates.length && !!clinic.listDates[0].length &&
                    <MoreDates listDates={clinic.listDates} endTime={getEndDate()} startTime={getStartDate()}/>
                  }
                </When>
              </RightSide>
              <GoogleMaps center={{lat: +clinic.LocationPoint._lat, lng: +clinic.LocationPoint._long}}/>
              {isMobile && !isTablet &&
                <>
                  <Price>{clinic.Cost}</Price>
                  <Button 
                    href={clinic.CoachData?.CoachURL}
                    onClick={handleRegisterClick}
                  >
                    {clinic.Spots > 0 ? 'Register' : 'Join the Waitlist'}
                  </Button>
                </>
              }
            </>
          }
          {
            showCoachTab && !!clinic.CoachData && 
            <AboutCoach 
              coach={clinic.CoachData} 
              coachRef={clinic.Coach} 
              clinicImage={clinic.ClinicImage} 
              handleBack={() => setShowCoachTab(false)} 
              backgroundColor={clinic.colorBackground}
            />
          }
        </Wrapper>
    </Modal>
  )
}

export default AboutClinicModal;